<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div v-show="view === 'list'">
      <v-alert
        v-if="hasExpiringCertificates()"
        class="mt-12"
        type="error"
        title="Alert title"
        text=""
      >
        {{ expiring_certifications.length }} certifications about to expire in
        the next 30 days!
        <span
          style="color: blue; cursor: pointer"
          @click="showExpiringCertificates()"
        >
          Show
        </span>
      </v-alert>
      <v-app-bar flat color="transparent" class="main-appbar p-0">
        <app-bar-nav-icon />
        <v-toolbar-title class="headline font-weight-bold ml-0"
          >Certifications</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          class="shrink"
          style="width: 300px"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label=""
          v-model="params.q"
        ></v-text-field>
        <v-btn icon @click="search()">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </v-app-bar>
      <v-simple-table>
        <thead>
          <tr>
            <th width="16%" class="text-left pa-0">Name</th>
            <th width="16%" class="text-left">Email</th>
            <th width="16%" class="text-left">Type</th>
            <th width="16%" class="text-left">Status</th>
            <th width="16%" class="text-left">Expiration date</th>
            <th width="20%" class="text-left">Submitted date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cert, index) in certifications" :key="index">
            <td>{{ cert.user ? cert.user.full_name : ' ' }}</td>
            <td>{{ cert.user ? cert.user.email : ' ' }}</td>
            <td>{{ cert.certificate_type }}</td>
            <td>
              <a href="javascript:" @click="statusSelected(cert.map_id)">{{
                cert.status
              }}</a>
            </td>
            <td>{{ cert.expiration_date_formatted }}</td>
            <td>{{ cert.added_at }}</td>
          </tr>
        </tbody>
        <tbody style="border: none">
          <template v-if="certification.loading" style="border: none">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
      <!--
      <v-row class="mt-6" justify="center" v-show="hasMore()">
        <v-btn depressed color="default" @click="more()" :loading="loading">
          More
          {{ certification.current_page + ' --- ' + certification.last_page }}
        </v-btn>
      </v-row>
      -->
      <v-pagination
        :length="meta.last_page"
        class="my-6"
        @input="updatePage"
        v-show="meta.last_page > 1"
        v-model="meta.current_page"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="520">
        <v-card>
          <v-card-title class="text-h5">
            Set Certification Status
          </v-card-title>
          <v-card-text>
            {{ certification.certificate_type }} certification of
            {{ certification.user.full_name }}
            <div>
              <ul>
                <li v-for="(f, index) in certification.files" :key="index">
                  <a target="_blank" :href="f.url">File {{ index + 1 }}</a>
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="success"
              @click="approve(certification.map_id)"
            >
              Approve
            </v-btn>
            <button
              type="button"
              class="v-btn v-btn--has-bg theme--light v-size--default error"
              @click="decline(certification.map_id)"
            >
              <span class="v-btn__content"> Reject </span>
            </button>
            <v-btn depressed color="" @click="dialog = false"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Api from '@/services/api'

export default {
  name: 'ContentPage',

  mixins: [ControlsMixin],

  data() {
    return {
      certifications: [],
      expiring_certifications: [],
      view: 'list',
      dialog: false,
      loading: false,
      certification: {
        certification_url: '',
        certificate_type: '',
        user: { full_name: '' },
        current_page: 1,
        total_pages: 1,
        total_records: 0,
        last_page: 1,
        loading: false,
      },
      params: {
        q: '',
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 15,
        to: 6,
        total: 6,
      },
    }
  },

  components: {
    AppBarNavIcon,
  },

  methods: {
    more() {
      this.current_page = this.current_page + 1
      this.getCertifications(this.current_page)
    },
    search() {
      this.getCertifications(1)
    },
    async getCertifications(page = 1) {
      this.certification.loading = true
      this.certifications = []
      const response = await Api.get(
        '/certifications?with_user=1&page=' + page,
        {
          responseType: 'json',
          page: this.current_page,
          params: this.params,
        }
      )

      // this.certifications = this.certifications.concat(response.data.data)
      this.certifications = response.data.data
      this.meta = response.data.meta
      // this.current_page = response.data.meta.current_page
      // this.total_records = this.meta.total
      // this.last_page = this.meta.last_page
      this.certification.loading = false
    },
    async getExpiringCertifications() {
      const response = await Api.get('/certifications/expiring', {
        responseType: 'json',
        page: this.current_page,
      })

      this.expiring_certifications = response.data.data
    },

    statusSelected(mapId) {
      // this.view = view
      this.certificationSelected(mapId)
      this.dialog = true
    },
    certificationSelected(mapId) {
      this.certification = this.certifications.find(
        (cert) => cert.map_id === mapId
      )
    },
    decline: async function (mapId) {
      this.changeStatus(mapId, 'decline')
    },
    approve: function (mapId) {
      this.changeStatus(mapId, 'approve')
    },
    hasMore() {
      return this.current_page < this.last_page
    },
    hasExpiringCertificates() {
      return this.expiring_certifications.length
    },
    showExpiringCertificates() {
      this.certifications = this.expiring_certifications
    },
    changeStatus: async function (mapId, status) {
      const response = await Api.post('/certification/' + status, {
        map_id: mapId,
      })

      let idx = this.certifications.findIndex((e) => e.map_id === mapId)
      this.certifications[idx] = response.data.data

      this.dialog = false
    },
    updatePage(pageIndex) {
      this.meta.current_page = pageIndex
      this.certifications = []
      this.getCertifications(pageIndex)
    },
  },

  mounted() {
    this.getCertifications(1)
    this.getExpiringCertifications()
  },
}
</script>
